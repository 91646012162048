import {useForm} from "react-hook-form";
import {useRecoilState} from "recoil";
import {Link, navigate} from "gatsby";
import * as React from "react";
import {formState} from './state'
import {useEffect, useRef} from "react";
import axios from "axios";


export const Daten = () => {
    const {register, handleSubmit} = useForm();
    const [formData, setFormData] = useRecoilState(formState);

    let serverResponse = useRef();

    const onSubmit = (data) => {
        if(!data.email && formData.email){
            data.email = formData.email;
        }
        if(!data.name && formData.name){
            data.name = formData.name;
        }
        if(!data.tel && formData.tel){
            data.tel = formData.tel;
        }
        if (data.email && data.name && data.tel) {
            setFormData({...formData, ...data});

            var params = new URLSearchParams();

            params.append('name', data.name);
            params.append('email', data.email);
            params.append('tel', data.tel);

            axios.post('https://api.fleurdenuit.at/buchen-step1.php', params)
                .then(function (response) {
                })
                .catch(function (error) {
                });

            document.querySelector('.progressbar').classList.add('half');
            navigate("/buchen/event");
        } else {
            serverResponse.current.innerText = "Bitte fülle alle Pflichtfelder aus!";
            serverResponse.current.style.color = "#F05353";
        }
    };

    useEffect(() => {
        if (formData.name) {
            document.querySelector('#buchenname').value = formData.name;
        }
        if (formData.email) {
            document.querySelector('#buchenemail').value = formData.email;
        }
        if (formData.tel) {
            document.querySelector('#buchentel').value = formData.tel;
        }
    });

    return (
        <div className="kontakt__form__outer">

            <form onSubmit={handleSubmit(onSubmit)} className={"kontakt__form buchung__form"}>
                <div className={"label__container"}>
                    <label className="kontakt__label">
                        <p className="clash ">*Name:</p>
                        <input type="text" name="buchenname" className="kontakt__input" id="buchenname" {...register("name")}/>
                    </label>
                    <label className="kontakt__label">
                        <p className="clash ">*E-Mail:</p>
                        <input type="email" name="buchenemail" className="kontakt__input" id="buchenemail" {...register("email")}/>
                    </label>
                    <label className="kontakt__label">
                        <p className="clash ">*Telefon:</p>
                        <input type="tel" name="buchentel" className="kontakt__input" id="buchentel" {...register("tel")}/>
                    </label>
                    <p className="small serverresponse clash" ref={serverResponse}></p>
                </div>

                <div className="btns__container">
                    <input type="submit" className={"btn btn-form-buchen bg-dark color-light clash small hover-moveup"} value={"Weiter"}/>
                </div>
            </form>
        </div>
    );
};

export const Event = () => {
    const {register, handleSubmit} = useForm();
    const [formData, setFormData] = useRecoilState(formState);

    let serverResponse = useRef();

    const onSubmit = (data) => {
        if(!data.art && formData.art){
            data.art = formData.art;
        }
        if(!data.ort && formData.ort){
            data.ort = formData.ort;
        }
        if(!data.datum && formData.datum){
            data.datum = formData.datum;
        }
        if(!data.spielzeit && formData.spielzeit){
            data.spielzeit = formData.spielzeit;
        }
        if (data.art && data.ort && data.datum) {
            setFormData({...formData, ...data});
            document.querySelector('.progressbar').classList.add('full');
            navigate("/buchen/uebersicht");
        } else {
            serverResponse.current.innerText = "Bitte fülle alle Pflichtfelder aus!";
            serverResponse.current.style.color = "#F05353";
        }
    };

    const back = () => {
        document.querySelector('.progressbar').classList.remove('half');
        navigate("/buchen/daten");
    };

    useEffect(() => {
        if (formData.ort) {
            document.querySelector('#buchenort').value = formData.ort;
        }
        if (formData.art) {
            document.querySelector('#buchenart').value = formData.art;
        }
        if (formData.spielzeit) {
            document.querySelector('#buchenzeit').value = formData.spielzeit;
        }
        if (formData.datum) {
            document.querySelector('#buchendatum').value = formData.datum;
        }
    });

    return (
        <div className="kontakt__form__outer">
            <form onSubmit={handleSubmit(onSubmit)} className={"kontakt__form buchung__form"}>
                <div  className={"label__container"}>
                    <label className="kontakt__label">
                        <p className="clash  ">*Art der Veranstaltung:</p>
                        <div className="select__wrapper">
                            <select name="buchenart" id="buchenart" className="kontakt__input selectinput" {...register("art")}>
                                <option value="Hochzeit">Hochzeit</option>
                                <option value="Firmenfeier">Firmenfeier</option>
                                <option value="Geburtstag">Geburtstag</option>
                                <option value="Öffentliche Veranstaltung">Öffentliche Veranstaltung</option>
                                <option value="Andere">Andere</option>
                            </select>
                        </div>
                    </label>
                    <label className="kontakt__label">
                        <p className="clash  ">*Ort:</p>
                        <input type="text" name="buchenort" className="kontakt__input" id="buchenort" {...register("ort")}/>
                    </label>
                    <label className="kontakt__label">
                        <p className="clash  ">*Datum:</p>
                        <input type="date" name="buchendatum" className="kontakt__input" id="buchendatum" {...register("datum")}/>
                    </label>
                    <label className="kontakt__label">
                        <p className="clash  ">Spielzeit:</p>
                        <input type="text" name="buchenzeit" className="kontakt__input" id="buchenzeit" {...register("spielzeit")}/>
                    </label>
                    <p className="small serverresponse clash" ref={serverResponse}></p>
                </div>

                <div className="btns__container">
                    <div className={"clash back hover-moveup"} onClick={() => {
                        back()
                    }}>Zurück
                    </div>
                    <input type="submit" className={"btn btn-form-buchen bg-dark color-light clash hove-moveup"} value={"Weiter"}/>
                </div>
            </form>
        </div>
    );
};

export const Uebersicht = () => {
    const {register, handleSubmit} = useForm();

    const [formData, setFormData] = useRecoilState(formState);

    let serverResponse = useRef();

    const onSubmit = () => {

        console.log(formData);

        let kontaktnachricht = document.querySelector('#kontaktnachricht').innerText;

        var params = new URLSearchParams();

        params.append('name', formData.name);
        params.append('email', formData.email);
        params.append('tel', formData.tel);
        params.append('art', formData.art);
        params.append('ort', formData.ort);
        params.append('spielzeit', formData.spielzeit);
        params.append('nachricht', kontaktnachricht);
        params.append('datum', formData.datum);

        navigate("/buchen/abgeschickt");

        axios.post('https://api.fleurdenuit.at/buchen.php', params)
            .then(function (response) {
                navigate("/buchen/abgeschickt");
            })
            .catch(function (error) {
                serverResponse.current.style.color = "#F05353";
                serverResponse.current.innerText = "Es gab einen Fehler beim Absenden der Nachricht. Bitte versuche es erneut.";
                console.log(error);
            });
    };

    const back = () => {
        document.querySelector('.progressbar').classList.remove('full');
        navigate("/buchen/event");
    };

    return (
        <div className="kontakt__form__outer">
            <form onSubmit={handleSubmit(onSubmit)} className={"kontakt__form buchung__form"}>
                <div className={"label__container"}>
                    <label className="kontakt__label">
                        <p className="clash  ">Zusätzliche Nachricht:</p>
                        <span className="textarea kontakt__input clash" id="kontaktnachricht" role="textbox" contentEditable></span>
                    </label>
                    <p className="small serverresponse clash" ref={serverResponse}></p>
                </div>

                <div className="btns__container">
                    <div className={"clash back hover-moveup"} onClick={() => {
                        back()
                    }}>Zurück
                    </div>
                    <input type="submit" className={"btn btn-form-buchen bg-dark color-light clash hove-moveup"} value={"Absenden"}/>
                </div>
            </form>
        </div>
    );
};


export const Abgeschickt = () => {
    return (
        <div className="kontakt__form__outer">
            <div className="kontakt__form buchung__form">
                <p className="large clash centered">Nachricht wurde erfolgreich abgeschickt. <br/> Wir melden uns demnächst!</p>

                <Link to={"/"} className="btn bg-dark color-light clash hover-moveup">Startseite</Link>
            </div>

        </div>
    );
};
