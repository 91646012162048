import * as React from "react"
import Layout from "../components/Layout";
import {graphql, navigate} from 'gatsby';
import {Router} from "@reach/router";
import {Uebersicht, Daten, Event, Abgeschickt} from "../components/buchen"
import SEO from "../components/SEO";


export const query = graphql`
    {
    allSanitySettings{
        edges {
      node {
        addresszeile1
        addresszeile2
        addresszeile3
        kontaktemail
        telefon
        instalink
        fblink
      }
    }
    }
    allSanityStartseite {
    edges {
      node {
      livevid
      albumcovers {
          _key
        }
      }
      }
      }
  }
`


const Buchen = ({data}) => {

    const page = data.allSanityStartseite.edges[0].node;

    return (
        <Layout impressum={true} settings={data.allSanitySettings.edges[0].node} live={page.livevid ? true : false} repertoire={page.albumcovers ? true : false}>
            <SEO title={"Buchung | Fleur de Nuit"} description={"Buche uns jetzt für deine Hochzeit, Geburtstag, Firmenevent oder einer anderen Veranstaltung."}/>

            <div className="fullpage">
                <div className="fullpage__inner">
                    <div className="form__container">
                        <div className="form__header">
                            <div className="steps">
                                <p className="large clash">Persönliche Daten</p>
                                <p className="large clash">Euer Event</p>
                                <p className="large clash">Absenden</p>
                            </div>
                            <div className="progressbar__container">
                                <div className="progressbar"></div>
                                <div className="progressbar__bg"></div>
                            </div>
                        </div>
                        <div className="router__container">
                            <Router>
                                <Daten path="/buchen/daten" />
                                <Event path="/buchen/event" />
                                <Uebersicht path="/buchen/uebersicht" />
                                <Abgeschickt path="/buchen/abgeschickt" />
                            </Router>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Buchen

